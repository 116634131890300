<template>
  <div>
    <span class="primary--text"
      style="font-size: 20px; padding-bottom: 5px !important;"><h3>{{loops[0].title}}</h3></span>
    <div v-for="(loop, i) in loops" style="margin-top: 0 !important;">
      <span class="primary--text" style="font-size: 20px; padding-bottom: 5px !important;"
        v-if="i>0"><h3>{{loop.title}}</h3></span>
      <div :id="'dropDiv'+i" class="dropDiv" @click="triggerFile(i)" style="margin-bottom: 10px; ">
        <input style="display: none;" type="file" v-on:input="handleFileUpload($event,i)" :id="'file'+i"/>
        <div class="dropzone-custom-content" :id="'msg-div'+i">
          <div class="subtitle">... double click to select a file from your computer</div>
          <div class="subtitle"><i>Limit size is <b>{{loop.size}} Mbs</b> & allowed file is <b>{{loop.type}}</b>
            only</i>
          </div>
        </div>
        <div class="dropzone-custom-content" :id="'msg'+i"
          style="display: none; text-align: left !important; margin-left: 20px;">
          <strong>Uploaded File: </strong><i :id="'uploadedFile'+i"></i><br>
          <strong>Uploaded File Size: </strong><i :id="'uploadedSize'+i"></i> Mbs<br>
          <i :id="'responceDiv'+i" style="font-size: 14px;"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'docs-uploader',
  props: {
    url: '',
    name: '',
    size: '',
    mimetype: {
      type: String,
      default: 'application',
    },
    type: {
      type: String,
      default: 'pdf',
    },
  },
  data: () => ({
    document: [],
    header: [],
    logger: [],
    loops: [],
  }),
  created (i) {
    if (i === 3) {
      this.type = 'jpeg'
      alert(i)
    }
    this.fetchDocumentSettings()
  },
  methods: {
    async fetchDocumentSettings () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_documents_settings/1',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.loops = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        }
        if (error.response && error.response.status === 400) {
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    triggerFile (i) {
      const uploads = document.getElementById('dropDiv' + i)
      const file = document.getElementById('file' + i)
      uploads.addEventListener('click', () => {
        file.click()
      })
    },
    async handleFileUpload (event, i) {
      this.document[i] = event.target.files
      var formData = new FormData()
      var imagefile = document.querySelector('#file' + i)
      console.log(imagefile.files[0])
      var msg = document.getElementById('msg-div' + i)
      var msgDiv = document.getElementById('msg' + i)
      var uploadedFile = document.getElementById('uploadedFile' + i)
      var uploadedSize = document.getElementById('uploadedSize' + i)
      var responceDiv = document.getElementById('responceDiv' + i)
      var size = parseFloat(parseInt(imagefile.files[0].size) / 1024 / 1024).toFixed(2)
      if (size > this.size || imagefile.files[0].type !== this.mimetype + '/' + this.type) {
        uploadedSize.textContent = size
        msgDiv.style.display = 'block'
        uploadedFile.textContent = imagefile.files[0].name
        msg.style.display = 'none'
        formData.append(this.name, imagefile.files[0], this.loops[i].id)
        responceDiv.textContent = 'Try to upload large file Or Bad file format'
        responceDiv.style.color = 'red'
      } else {
        uploadedSize.textContent = size
        msgDiv.style.display = 'block'
        uploadedFile.textContent = imagefile.files[0].name
        msg.style.display = 'none'
        formData.append(this.name, imagefile.files[0])
        formData.append('id', this.loops[i].id)
        if (localStorage.getItem('logged_user')) {
          this.logger = JSON.parse(localStorage.getItem('logged_user'))
        }
        this.header = this.logger.accessToken
        responceDiv.textContent = 'please wait...'
        await axios.post(this.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.header,
          },
        }).then(response => {
          responceDiv.innerHTML = response.data.message
          responceDiv.style.color = '#00b782'
          if (response.data.success) {
            localStorage.setItem('document', response.data.document)
            uploadedSize.textContent = size
            msgDiv.style.display = 'block'
            uploadedFile.textContent = imagefile.files[0].name
            i = i + 1
            if (i < this.loops.length) {
              this.$emit('getResponse', 'saved')
            } else {
              this.$emit('getResponse', 'completed')
            }
          }
        })
      }
    },
  },
}
</script>
<style>
  .dropzone-custom-title {
    margin-top: 0;
    color: #00b782;
  }

  .subtitle {
    color: #314b5f;
  }

  .dropDiv {
    background-color: rgba(89, 87, 85, 0.06);
    padding-top: 17px;
    position: relative;
    text-align: center;
    height: 100px;
    cursor: pointer;
    border: dashed;
  }
</style>
