<template>
  <div class="item-container">
    <v-app>
      <v-dialog
        v-model="loginModal"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Password</span>
          </v-card-title>
          <b-container>
            <v-row>
              <input type="hidden" v-model="id">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="password"
                  label="New password"
                  name="Extend"
                  type="password"
                  v-validate="'required'"
                  outlined
                  dense
                ></v-text-field>
                <span style="color: red;"
                  v-show="errors.has('Extend')">{{ errors.first('Extend') }}</span>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="confirmpassword"
                  label="confirm password"
                  name="confirm password"
                  type="password"
                  v-validate="'required'"
                  outlined
                  dense
                ></v-text-field>
                <span style="color: red;"
                  v-show="errors.has('confirm password')">{{ errors.first('confirm password') }}</span>
              </v-col>
            </v-row>
          </b-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="change_password"
              style="text-transform: none;"
              outlined
            >
              Change
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        :timeout="2000"
        :value="true"
        absolute
        top
        color="success"
        right
        text
        v-if="appear"
      >
        Document Changed
      </v-snackbar>
      <v-stepper v-model="e1" style="overflow:auto !important;">
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
            Parents information
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
            Secondary Studies
          </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step
            :complete="e1 > 3"
            step="2"
          >
            Student Documents
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            Student choice
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
              class="mb-12"
              color="white"
            >
              <v-card>
                <v-row>
                  <v-col cols="4" style="background-color: springgreen; margin: 0;">
                    <img src="@/assets/icons/3.png" style="width: 100%; height: 75%; margin-top: 70px;">
                  </v-col>
                  <v-col cols="5" style="margin-top: 40px;">
                    <center><img src="@/assets/icons/1.jpg" style="width: 100px; height: 100px;"></center>
                    <div v-if="isVisible == 'appear'">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Father's names" dense outlined v-model="parentInfo.father_names"
                            name="Father name" v-validate="'required'" id="fatherName" @dblclick="enableTextBox('fatherName')" @blur="updateData('fatherName',parentInfo.id,'father_names','parent info')" @keyup.enter="updateData('fatherName',parentInfo.id,'father_names','parent info')" readonly></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Father name') }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Mother's names" dense outlined v-model="parentInfo.mother_names"
                            v-validate="'required'" name="Mother names" id="motherName" @dblclick="enableTextBox('motherName')" @blur="updateData('motherName',parentInfo.id,'mother_names','parent info')" @keyup.enter="updateData('motherName',parentInfo.id,'mother_names','parent info')" readonly></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Mother names') }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field label="District/Territory" dense outlined v-model="parentInfo.district"
                            v-validate="'required'" name="District" id="district" @dblclick="enableTextBox('district')" @blur="updateData('district',parentInfo.id,'district','parent info')" @keyup.enter="updateData('district',parentInfo.id,'district','parent info')" readonly></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('District') }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Province" dense outlined v-model="parentInfo.province"
                            v-validate="'required'" name="Province" id="province" @dblclick="enableTextBox('province')" @blur="updateData('province',parentInfo.id,'province','parent info')" @keyup.enter="updateData('province',parentInfo.id,'province','parent info')" readonly></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Province') }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Guardian phone number" dense outlined v-model="parentInfo.parent_phone"
                            name="Guardian phone" id="parentPhone" @dblclick="enableTextBox('parentPhone')" @blur="updateData('parentPhone',parentInfo.id,'parent_phone','parent info')" @keyup.enter="updateData('parentPhone',parentInfo.id,'parent_phone','parent info')" readonly></v-text-field>
                        </v-col>
                      </v-row>
                      <v-text-field label="Guardian Id number" dense outlined v-model="parentInfo.idNumber"
                        name="Guardian Identity" id="parentId" @dblclick="enableTextBox('parentId')" @blur="updateData('parentId',parentInfo.id,'idNumber','parent info')" @keyup.enter="updateData('parentId',parentInfo.id,'idNumber','parent info')" readonly>
                        <span style="color: red;">*</span></v-text-field>
                    </div>
                    <div v-if="isVisible !== 'appear'">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Father names" dense outlined v-model="father" name="Father name"
                            v-validate="'required'"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Father name') }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Mother names" dense outlined v-model="mother" v-validate="'required'"
                            name="Mother names"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Mother names') }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field label="Territory/district" dense outlined v-model="district"
                            v-validate="'required'" name="Territory/district"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Territory/district') }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Province" dense outlined v-model="province" v-validate="'required'"
                            name="Province"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Province') }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Parent phone number" dense outlined v-model="phone"
                            name="Guardian's phone" v-validate="'required|numeric'"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Guardian\'s phone') }}</span>
                        </v-col>
                      </v-row>
                      <v-text-field label="Parent Id number" dense outlined v-model="idNumber" name="Parent's Identity"
                        v-validate="'required|numeric'"></v-text-field>
                      <span style="color: red;" v-if="valid">{{ errors.first('Parent\'s Identity') }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
            <v-btn
              class="btn-next"
              @click="info();"
              color="primary"
            >
              >>
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <img src="@/assets/icons/1.jpg" alt="Logo" class="logo">
            <v-card>
              <v-row>
                <v-col cols="4" style="background-color: springgreen; margin: 0;">
                  <img src="@/assets/icons/step2.png" style="width: 100%; height: 75%; margin-top: 70px;">
                </v-col>
                <v-col cols="7" style="margin-top: 40px;">
                  <center><img src="@/assets/icons/1.jpg" style="width: 100px; height: 100px;"></center>
                  <v-row style="margin-left: 20px !important;">
                    <v-row v-if="studyVisible ==='studyVisible'">
                      <v-col cols="6">
                        <v-text-field label="Diploma number" dense outlined v-model="studydata.diploma_number"
                          name="Diploma" id="diplomaNumber" @dblclick="enableTextBox('diplomaNumber')" @blur="updateData('diplomaNumber',studydata.id,'diploma_number')" @keyup.enter="updateData('diplomaNumber',studydata.id,'diploma_number')" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Place of issue" dense outlined v-model="studydata.issued"
                          name="place of issue" id="issued" @dblclick="enableTextBox('issued')" @blur="updateData('issued',studydata.id,'issued')" @keyup.enter="updateData('issued',studydata.id,'issued')" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Diploma number" dense outlined v-model="studydata.issue_date"
                          name="Diploma" id="issueDate" @dblclick="enableTextBox('issueDate')" @blur="updateData('issuedDate',studydata.id,'issued_date')" @keyup.enter="updateData('issuedDate',studydata.id,'issued_date')" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Province" id="pro" dense outlined v-model="studydata.province" name="place of issue" @dblclick="enableTextBox('pro')" @blur="updateData('pro',studydata.id,'province')" @keyup.enter="updateData('pro',studydata.id,'province')"
                          readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="School name" dense outlined v-model="studydata.school_name" name="Diploma" id="schoolName" @dblclick="enableTextBox('schoolName')" @blur="updateData('schoolName',studydata.id,'school_name')" @keyup.enter="updateData('schoolName',studydata.id,'school_name')"
                          readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Section" dense outlined v-model="studydata.section" name="place of issue" id="section" @dblclick="enableTextBox('section')" @blur="updateData('section',studydata.id,'section')" @keyup.enter="updateData('section',studydata.id,'section')"
                          readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Option" dense outlined v-model="studydata.options" name="Diploma" id="options" @dblclick="enableTextBox('options')" @blur="updateData('options',studydata.id,'options')" @keyup.enter="updateData('options',studydata.id,'options')" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Code" dense outlined v-model="studydata.code" id="code" name="place of issue" @dblclick="enableTextBox('code')" @blur="updateData('code',studydata.id,'code')" @keyup.enter="updateData('code',studydata.id,'code')"
                          readonly>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row v-if="studyVisible !== 'studyVisible'" style="margin-left: 20px !important;">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field label="Diploma number" dense outlined v-model="diploma"
                          v-validate="'required|numeric'" name="Diploma">
                        </v-text-field>
                      </v-col>
                      <v-col cols6>
                        <v-text-field label="Place of issue" dense outlined v-model="issued" v-validate="'required'"
                          name="place of issue">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="issueDate"
                              label="date of Issue"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="issueDate" @input="menu3 = false"
                            name="Date of issue"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="province" dense outlined v-model="pro"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field label="School name" dense outlined v-model="school" v-validate="'required'"
                          name="School name"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Section" dense outlined v-model="section" v-validate="'required'"
                          name="Section"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field label="Option" dense outlined v-model="option" v-validate="'required'"
                          name="Option"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Code" dense outlined v-model="code" v-validate="'required|numeric'"
                          name="Code" @keyup="disableBtnNext"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
            <v-btn
              color="primary"
              @click="studyInfo();"
              class="btn-next"
              style="margin-top: 15px;"
              :disabled="btnNext"
            >
              >>
            </v-btn>

            <v-btn color="primary" class="btn-prev" @click="e1=1" style="margin-top: 15px;"> <<
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div>
              <center>
                <div class="text">
                  <i class="fa fa-exclamation-circle icon" aria-hidden="true"></i>

                  <div class="subtitle">... Click on pdf logo to view document.</div>
                  <div class="subtitle"><i>Click on document title to change document</i>
                  </div>
                </div>
              </center>
              <div v-if="imageShow === 'moveon'" style="">
                <div style="margin-right:52px !important;width: 100%;">
                  <image-uploader :url="'manipulateStudentPassport'" :name="'passport'" :size="2" :label="'Passport photo'"/>
                  <Uploader :type="type" :size="1" :url="url" :name="name" @getResponse="receiveResponse($event)"
                    style="height: 100%; margin-bottom: 0 !important;"/>
                  <div>
                    <v-btn color="primary" class="btn-prev" @click="back" v-if="imageShow === 'moveon'"> <<
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="fetchStudentsImages(); e1=4"
                      class="btn-next"
                      style="margin-top: 15px;"
                      v-if="imageShow === 'moveon'"
                      :disabled="disableNext"
                    >
                      >>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="imageShow === 'appear'">
              <v-row>
                <v-col cols="4" v-for="image in images" :key="image.id">
                  <template>
                    <v-layout>
                      <v-flex>
                        <v-card style="max-width: 70% !important;">
                          <a target="_blank" :href="baseUrl + 'assets/uploads/pdf/' + image.document">
                            <v-img
                              class="white--text"
                              height="250px"
                              :src="baseUrl + 'assets/uploads/pdf/pdf.png'"
                            >
                              <v-container fill-height fluid>
                                <v-layout fill-height>
                                  <v-flex xs12 align-end flexbox>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-img>
                          </a>
                          <v-card-title>
                            <div>
                              <v-tooltip bottom color="red">
                                <template v-slot:activator="{ on, attrs }">
                                  <span class="grey--text" @click="uploadModal(image.id)" v-bind="attrs"
                                    v-on="on">{{image.title}}</span>
                                </template>
                                <span>Change document</span>
                              </v-tooltip>
                            </div>
                          </v-card-title>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-col>
              </v-row>
              <v-btn
                color="primary"
                @click="e1=4"
                class="btn-next"
                style="margin-top: 15px;"
              >
                >>
              </v-btn>
            </div>
            <v-btn color="primary" class="btn-prev" @click="back" v-if="imageShow === 'appear'"> <<
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-card
              class="mb-12"
              color="white"
            >
              <div v-if="choiceVisible === 'choiceNotVisible'">
                <v-row>
                  <v-col cols="3" style="background-color: springgreen; margin: 0;">
                    <img src="@/assets/icons/6.png" style="width: 100%; height: 75%; margin-top: 70px;">
                  </v-col>
                  <v-col cols="9" style="margin-top: 40px;">
                    <img src="@/assets/icons/1.jpg" style="width: 100px; height: 100px; margin-left: 300px;"/>
                    <v-row v-for="(item,index) in rows" :key="item.id">
                      <v-col cols="1"><label class="input-label" v-html="Number(index+1)+''+label[index]"></label>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          label="Select Faculty"
                          :items="faculty"
                          outlined
                          dense
                          v-model="facultyOne[index]"
                          v-validate="'required'"
                          @change="fetchSelectedDepartments(index)"
                          name="First faculty">
                        </v-select>
                        <span style="color: red;" v-if="valid">{{ errors.first('First faculty') }}</span>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          label="Select department"
                          outlined
                          dense
                          :items="departments[index]"
                          @change="diableFinish"
                          v-model="deptOne[index]"
                          v-validate="'required'"
                          name="First department">
                        </v-select>
                        <span style="color: red;" v-if="valid">{{ errors.first('First department') }}</span>
                      </v-col>
                      <v-col cols="2">
                        <v-btn color="blue" outlined text style="text-transform: none" @click="addRow" v-if="show">Add
                          Choice
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-if="choiceVisible === 'choiceVisible'">
                <!--                ========================================================-->
                <v-row>
                  <v-col cols="3" style="background-color: springgreen; margin: 0;">
                    <img src="@/assets/icons/6.png" style="width: 100%; height: 75%; margin-top: 70px;">
                  </v-col>
                  <v-col cols="9" style="margin-top: 40px;">
                    <img src="@/assets/icons/1.jpg" style="width: 100px; height: 100px; margin-left: 300px;"/>
                    <div v-for="(choice,index) in choiceInfo" :key="choice.id">
                      <v-row>
                        <v-col cols="1"><label class="input-label" v-html="Number(index+1)+''+label[index]"></label>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="choice.faculty" outlined dense readonly></v-text-field>
                          <!--                          <v-select v-model="choice.chose-nFaculty.text" :items="faculty"></v-select>-->
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="choice.department" outlined dense readonly></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-btn
              color="primary"
              @click="choice"
              style="float: right;"
              :disabled="isDisabled"
              v-if="choiceVisible === 'choiceNotVisible'"
            >
              Finish
            </v-btn>
            <v-btn color="primary" class="btn-prev" @click="back"> <<
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-dialog
        v-model="changeDocument"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Edit Document</span>
          </v-card-title>
          <b-container>
            <div>
              <span class="primary--text"></span>
              <div style="margin-top: 0 !important;">
                <div :id="'dropDiv'" class="dropDiv" @click="triggerFile()" style="margin-bottom: 10px; ">
                  <input style="display: none;" type="file" v-on:input="handleFileUpload($event)" :id="'file'"/>
                  <div class="dropzone-custom-content" :id="'msg-div'">
                    <div class="subtitle">... double click to select a file from your computer</div>
                    <div class="subtitle"><i>Limit size is <b>1 Mbs</b> & allowed file is <b>pdf</b>
                      only</i>
                    </div>
                  </div>
                  <div class="dropzone-custom-content" :id="'msg'"
                    style="display: none; text-align: left !important; margin-left: 20px;">
                    <strong>Uploaded File: </strong><i :id="'uploadedFile'"></i><br>
                    <strong>Uploaded File Size: </strong><i :id="'uploadedSize'"></i> Mbs<br>
                    <i :id="'responceDiv'" style="font-size: 14px;"></i>
                  </div>
                </div>
              </div>
            </div>
          </b-container>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import Uploader from '../Uploader'
import Swal from 'sweetalert2'
import CircleBars from '../../statistics/progress-bars/Widgets/CircleBars'
import axios from 'axios'
import ImageUploader from '../ImageUploader'
export default {
  components: {
    Uploader,
    CircleBars,
    ImageUploader,
  },
  // props: { initialDoc: { type: String } },
  data () {
    return {
      test: {
        text: 'Divorced',
        value: 3,
      },
      changeDocument: false,
      initialDoc: '',
      type: 'pdf',
      url: 'upload_pdf',
      name: 'image',
      rows: 1,
      counter: 0,
      confirmpassword: '',
      password: '',
      sex: null,
      ValidityDate: '',
      menu3: false,
      gender: [
        {
          text: 'Male',
          value: 1,
        },
        {
          text: 'Female',
          value: 2,
        },
      ],
      civil: [
        {
          text: 'Single',
          value: 1,
        },
        {
          text: 'Married',
          value: 2,
        },
        {
          text: 'Divorced',
          value: 3,
        },
      ],
      departments: [],
      e1: 1,
      idNumber: '',
      province: '',
      district: '',
      phone: '',
      mother: '',
      father: '',
      selected: null,
      //  =======================
      issued: '',
      pro: '',
      diploma: '',
      issueDate: '',
      school: '',
      option: '',
      code: '',
      section: '',
      //= ===========================
      facultyOne: [],
      deptOne: [],
      header: '',
      logger: [],
      intakes: [],
      loginModal: false,
      id: '',
      valid: true,
      faculty: [],
      show: true,
      parentInfo: [],
      studydata: [],
      isVisible: '',
      studyVisible: '',
      load: 'false',
      loading: false,
      loaded: false,
      fileRecords: [],
      uploadUrl: 'https://www.mocky.io/v2/5d4fb20b3000005c111099e3',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [],
      imageShow: '',
      images: [],
      imgurl: 'http://dev.Qollege.rw/assets/uploads/pdf/pdf.png',
      label: ['<sup>st</sup> Choice', '<sup>nd</sup> Choice', '<sup>rd</sup> Choice'],
      isDisabled: true,
      choiceInfo: [],
      choiceVisible: '',
      btnNext: true,
      sendingMsg: false,
      image: [],
      loops: [],
      disableNext: true,
      mimetype: 'application',
      documentId: '',
      appear: false,
      chosenFaculty: [],
      chosenDept: [],
    }
  },
  computed: {
    baseUrl () {
      return this.axios.defaults.baseURL
    },
  },
  created () {
    this.fetchSelectedFacults()
    this.fetchParentsInfo()
    this.fetchStudyInfo()
    this.fetchStudentsImages()
    this.fetchChoiceInfo()
    this.fetchDocumentSettings()
  },
  methods: {
    enableTextBox (ref) {
      const txt = document.getElementById(ref)
      txt.removeAttribute('readonly')
    },
    updateData (ref, id, key, check) {
      const txt = document.getElementById(ref)
      txt.readOnly = true
      this.edit(id, key, txt.value, check)
    },
    async edit (id, key, value, check) {
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: id,
        key: key,
        value: value,
      }
      try {
        if (check === 'parent info') {
          await this.axios({
            url: 'manipulate_parent_info/10',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
        } else {
          await this.axios({
            url: 'manipulate_student_study/10',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(e)
        }
      }
    },
    uploadModal (id) {
      this.changeDocument = true
      this.documentId = id
    },
    async fetchDocumentSettings () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_documents_settings/1',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.loops = res.data
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        }
        if (error.response && error.response.status === 400) {
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    diableFinish () {
      if (this.deptOne.length === 3) {
        this.isDisabled = false
      } else {
        this.isDisabled = true
      }
    },
    receiveResponse (event) {
      if (event) {
        this.counter = this.counter + 1
      }
      if (this.counter === this.loops.length) {
        const msg = 'You are successfully uploaded required documents click Ok to finish your registration '
        Swal.fire({
          title: 'Uploaded!',
          text: msg,
          icon: 'success',
          confirmButtonText: 'Ok',
          preConfirm: () => {
            this.disableNext = false
          },
        })
      } else {
        this.disableNext = true
      }
    },
    addRow () {
      if (this.rows === 1) {
        this.show = true
      } else {
        this.show = false
      }
      if (this.rows < 3) {
        this.rows += 1
      }
    },
    async fetchSelectedDepartments (index) {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      await this.axios({
        url: 'get_selected_departments/' + this.facultyOne[index],
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + this.header,
        },
      }).then(res => {
        this.loading = false
        const checker = this.departments.indexOf(this.departments[index])
        if (checker === -1) {
          console.log(this.departments.push(res.data))
        } else {
          this.departments.splice(index, 1, res.data)
          console.log(this.departments[index])
        }
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.loading = false
        } else {
          console.log(error)
        }
      })
    },
    async fetchParentsInfo () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_parent_info',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.parentInfo = res.data
          this.isVisible = 'appear'
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.parentInfo = []
          this.isVisible = 'moveon'
        } else {
          console.log(error)
        }
      }
    },
    async fetchStudentsImages () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_student_documents',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.images = res.data
          this.imageShow = 'appear'
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.images = []
          this.imageShow = 'moveon'
        } else {
          console.log(error)
        }
      }
    },
    async fetchStudyInfo () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_study_info',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.btnNext = false
          this.studydata = res.data
          this.studyVisible = 'studyVisible'
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.btnNext = true
          this.studydata = []
          this.studyVisible = 'studyNotVisible'
        } else {
          console.log(error)
        }
      }
    },
    async fetchChoiceInfo () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_choices_info',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.choiceInfo = res.data.data
          this.choiceVisible = 'choiceVisible'
          this.chosenFaculty = res.data.faculties
          this.chosenDept = res.data.dept
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.choiceInfo = []
          this.choiceVisible = 'choiceNotVisible'
        } else {
          console.log(error)
        }
      }
    },
    back () {
      if (this.e1 === 2) {
        this.e1 = 1
      } else if (this.e1 === 3) {
        this.e1 = 2
      } else if (this.e1 === 4) {
        this.e1 = 3
      }
    },
    modal () {
      const user = localStorage.getItem('logged_user')
      const dash = JSON.parse(user)
      if (dash.login === '0') {
        this.loginModal = true
        this.id = dash.status
      }
    },
    async change_password () {
      if (this.password !== this.confirmpassword) {
        alert('Password not match')
        this.password = ''
        this.confirmpassword = ''
      }
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      const data = {
        id: this.id,
        password: this.password,
      }
      try {
        const res = await this.axios({
          url: 'change_password',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loginModal = false
          this.password = ''
          this.comfirmpassword = ''
          this.$router.push({ name: 'login-page' })
        } else {
          alert('Data not saved')
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (e.response && e.response.status === 400) {
          alert(e.response.data.messages)
          this.names = ''
          this.selected = ''
        } else {
          console.log(e)
        }
      }
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
        } else {
          // form have errors
        }
      })
    },
    async validateStudy () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.studyInfo()
        } else {
          // form have errors
        }
      })
    },
    async validateChoice () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.choice()
        } else {
          // form have errors
        }
      })
    },
    disableBtnNext () {
      if (this.diploma === '' || this.issued === '' || this.issueDate === '' || this.pro === '' || this.school === '' || this.section === '' || this.option === '' || this.code === '') {
        this.btnNext = true
      } else if (this.studyVisible !== 'studyVisible') {
        this.btnNext = false
      } else {
        this.btnNext = false
      }
    },
    async info () {
      this.sendingMsg = true
      this.load = true
      if (this.isVisible === 'appear') {
        this.e1 = 2
      } else {
        if (this.father === '' || this.mother === '' || this.province === '' || this.district === '') {
          this.validate()
        } else {
          if (localStorage.getItem('logged_user')) {
            this.logger = JSON.parse(localStorage.getItem('logged_user'))
          }
          this.header = this.logger.accessToken
          const data = {
            father: this.father,
            mother: this.mother,
            idNumber: this.idNumber,
            province: this.province,
            district: this.district,
            phone: this.phone,
          }
          try {
            const res = await this.axios({
              url: 'manipulate_parent_info',
              method: 'post',
              data: data,
              headers: {
                Authorization: 'Bearer ' + this.header,
              },
            })
            if (res.status === 200) {
              this.fetchParentsInfo()
              this.sendingMsg = false
              this.load = false
              this.e1 = 2
              this.father = ''
              this.mother = ''
              this.idNumber = ''
              this.province = ''
              this.district = ''
              this.territory = ''
              this.phone = ''
              this.valid = false
            } else {
              alert('Data not saved')
            }
          } catch (e) {
            if (e.response && e.response.status === 401) {
              localStorage.removeItem('logged_user')
              this.$router.push({ name: 'login-page' })
            } else if (e.response && e.response.status === 400) {
              this.sendingMsg = false
              this.load = false
              Swal.fire({
                title: 'Error!',
                text: e.response.data.messages,
                icon: 'error',
                confirmButtonText: 'Ok',
                preConfirm: () => {
                  this.e1 = 2
                  this.father = ''
                  this.mother = ''
                  this.idnumber = ''
                  this.province = ''
                  this.district = ''
                  this.territory = ''
                  this.phone = ''
                  this.valid = false
                },
              })
            } else {
              console.log(e)
            }
          }
        }
      }
    },
    async studyInfo () {
      this.loading = true
      if (this.studyVisible === 'studyVisible') {
        this.e1 = 3
      } else {
        if (this.diploma === '' || this.issued === '' || this.issueDate === '' || this.pro === '' || this.school === '' || this.section === '' || this.option === '' || this.code === '') {
          this.validate()
        } else {
          if (localStorage.getItem('logged_user')) {
            this.logger = JSON.parse(localStorage.getItem('logged_user'))
          }
          this.header = this.logger.accessToken
          const data = {
            diploma: this.diploma,
            issued: this.issued,
            issueDate: this.issueDate,
            province: this.pro,
            school: this.school,
            section: this.section,
            option: this.option,
            code: this.code,
          }
          try {
            const res = await this.axios({
              url: 'manipulate_student_study',
              method: 'post',
              data: data,
              headers: {
                Authorization: 'Bearer ' + this.header,
              },
            })
            if (res.status === 200) {
              this.fetchStudyInfo()
              this.loading = false
              this.e1 = 3
              this.diploma = ''
              this.issued = ''
              this.issueDate = ''
              this.pro = ''
              this.school = ''
              this.section = ''
              this.option = ''
              this.code = ''
              this.valid = false
            } else {
              alert('Data not saved')
            }
          } catch (e) {
            if (e.response && e.response.status === 401) {
              localStorage.removeItem('logged_user')
              this.$router.push({ name: 'login-page' })
            } else if (e.response && e.response.status === 400) {
              Swal.fire({
                title: 'Error!',
                text: e.response.data.messages,
                icon: 'error',
                confirmButtonText: 'Ok',
                preConfirm: () => {
                  this.e1 = 3
                  this.diploma = ''
                  this.issued = ''
                  this.issueDate = ''
                  this.pro = ''
                  this.school = ''
                  this.section = ''
                  this.option = ''
                  this.code = ''
                  this.valid = false
                },
              })
            } else {
              console.log(e)
            }
          }
        }
      }
    },
    async choice () {
      // if (this.facultyOne === '' || this.deptOne === '') {
      //   alert('It is empty')
      //   this.validateChoice()
      // } else {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      const data = {
        firstfaculty: this.facultyOne,
        firstdept: this.deptOne,
      }
      try {
        const res = await this.axios({
          url: 'manipulate_student_choice',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loaded = false
          this.facultyOne = ''
          this.deptOne = ''
          this.valid = false
          Swal.fire({
            title: 'You Completed Registration!',
            text: 'We will get back to you via your email for further information',
            icon: 'success',
            confirmButtonText: 'Ok',
            preConfirm: () => {
              window.location.reload()
            },
          })
          this.$router.push({ name: 'dashboard' })
        } else {
          alert('Data not saved')
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (e.response && e.response.status === 400) {
          this.loaded = false
          Swal.fire({
            title: 'Error!',
            text: e.response.data.messages,
            icon: 'error',
            confirmButtonText: 'Ok',
            preConfirm: () => {
              this.rows = 1
              this.show = true
              this.e1 = 3
              this.facultyOne = ''
              this.deptOne = ''
              this.valid = false
            },
          })
        } else {
          console.log(e)
        }
      }
      // }
    },
    async fetchSelectedFacults () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_faculty',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.faculty = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async uploadFiles () {
      // if (this.first !== 1 || this.second !== 1 || this.third !== 1) {
      //   Swal.fire({
      //     title: 'Error!',
      //     text: 'Upload all above documents first!!',
      //     icon: 'error',
      //     confirmButtonText: 'Ok',
      //   })
      // } else {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      const data = {
        image: this.fileRecords,
      }
      try {
        const res = await this.axios({
          url: 'upload_photo',
          data: data,
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          const msg = 'You are successfully uploaded required documents click Ok to finish your registration '
          Swal.fire({
            title: 'Uploaded!',
            text: msg,
            icon: 'success',
            confirmButtonText: 'Ok',
            preConfirm: () => {
              this.e1 = 4
            },
          })
          console.log(res)
        } else {
          alert('Invalid credentials')
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.loading = false
          this.visible = true
        } else if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(e)
        }
      }
      // }
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord)
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
      console.log(this.fileRecordsForUpload)
      console.log(this.fileRecords)
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        var k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
      } else {
        if (confirm('Are you sure you want to delete?')) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
    triggerFile () {
      const uploads = document.getElementById('dropDiv')
      const file = document.getElementById('file')
      uploads.addEventListener('click', () => {
        file.click()
      })
    },
    async handleFileUpload (event) {
      this.appear = false
      this.document = event.target.files
      var formData = new FormData()
      var imagefile = document.querySelector('#file')
      console.log(imagefile.files[0])
      var msg = document.getElementById('msg-div')
      var msgDiv = document.getElementById('msg')
      var uploadedFile = document.getElementById('uploadedFile')
      var uploadedSize = document.getElementById('uploadedSize')
      var responceDiv = document.getElementById('responceDiv')
      var size = parseFloat(parseInt(imagefile.files[0].size) / 1024 / 1024).toFixed(2)
      if (size > this.size || imagefile.files[0].type !== this.mimetype + '/' + this.type) {
        uploadedSize.textContent = size
        msgDiv.style.display = 'block'
        uploadedFile.textContent = imagefile.files[0].name
        msg.style.display = 'none'
        formData.append(this.name, imagefile.files[0], this.documentId)
        responceDiv.textContent = 'Try to upload large file Or Bad file format'
        responceDiv.style.color = 'red'
      } else {
        uploadedSize.textContent = size
        msgDiv.style.display = 'block'
        uploadedFile.textContent = imagefile.files[0].name
        msg.style.display = 'none'
        formData.append(this.name, imagefile.files[0])
        formData.append('id', this.documentId)
        if (localStorage.getItem('logged_user')) {
          this.logger = JSON.parse(localStorage.getItem('logged_user'))
        }
        this.header = this.logger.accessToken
        responceDiv.textContent = 'please wait...'
        await axios.post(this.url + '/10', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.header,
          },
        }).then(response => {
          this.triggerFile()
          this.appear = true
          this.changeDocument = false
          this.fetchStudentsImages()
          responceDiv.innerHTML = response.data.message
          responceDiv.style.color = '#00b782'
          if (response.data.success) {
            uploadedSize.textContent = size
            msgDiv.style.display = 'block'
            uploadedFile.textContent = imagefile.files[0].name
          }
        })
      }
    },
  },
}
</script>

<style >
  .btn-next {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    color: #ffffff;
    float: right;
  }

  .btn-prev {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    color: #ffffff;
    float: left;
  }

  .item-container {
    margin: 0;
    width: 100%;
  }

  .logo {
    width: 100px;
    height: 100px;
    float: right;
  }

  .asset-container {
    background-color: #ecf0f5;
    width: 100%;
  }

  .asset-card {
    margin: 40px 250px 0 250px;
    background-color: #ffffff;
    padding: 10px;
    border-top: 4px solid lightgrey;
  }
  .input-label {
    font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
    font-weight: 400;
  }

  @media only screen and (max-width: 1440px) {
    .asset-card {
      margin: 0;
    }
  }

  @media only screen and (max-width: 500px) {
    .asset-card {
      width: 100%;
    }

    .inputs {
      margin: 0;
    }
  }
  .v-application--wrap{
    min-height: auto !important;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #00b782;
  }

  .subtitle {
    /*color: #babfc2;*/
    color: #21242a;
    font-size: 20px;
    margin-left: 10px;
  }

  .dropDiv {
    background-color: rgba(89, 87, 85, 0.06);
    padding-top: 17px;
    position: relative;
    text-align: center;
    height: 100px;
    cursor: pointer;
    border: dashed;
  }
  .grey--text:hover {
    cursor: pointer !important;
    color: #0e4ac4 !important;
    text-decoration: underline;
      }
  .text{
    width: 500px;
    border-radius: 2px;
    border-left: 4px solid #0e4ac4;
    background-color: #c9f7db;
  }
  .icon {
    position: relative;
    left: 5px;
    top:15px;
    float: left;
    font-size: 30px;
    color: #abb0c4;
  }
</style>
