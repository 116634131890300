<template>
  <div>
    <span class="primary--text"
      style="font-size: 20px; padding-bottom: 5px !important;"><h3>{{label}}</h3></span>
    <div style="margin-top: 0 !important;">
      <span class="primary--text" style="font-size: 20px; padding-bottom: 5px !important;"><h3>{{title}}</h3></span>
      <div :id="'dropDiv'" class="dropDiv" @click="triggerFile()" style="margin-bottom: 10px; ">
        <input style="display: none;" type="file" v-on:input="handleFileUpload($event)" :id="'file'"/>
        <div class="dropzone-custom-content" :id="'msg-div'">
          <div class="subtitle">... double click to select a file from your computer</div>
          <div class="subtitle"><i>Limit size is <b>{{size}} Mbs</b> & allowed file is <b>{{type}}</b>
            only</i>
          </div>
        </div>
        <div class="dropzone-custom-content" :id="'msg'"
          style="display: none; text-align: left !important; margin-left: 20px;">
          <strong>Uploaded File: </strong><i :id="'uploadedFile'"></i><br>
          <strong>Uploaded File Size: </strong><i :id="'uploadedSize'"></i> Mbs<br>
          <i :id="'responceDiv'" style="font-size: 14px;"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'docs-uploader',
  props: {
    label: '',
    title: '',
    url: '',
    name: '',
    size: '',
    mimetype: {
      type: String,
      default: 'image/png',
    },
    type: {
      type: String,
      default: 'png',
    },
  },
  data: () => ({
    document: [],
    header: [],
    logger: [],
    loops: '',
  }),
  created () {

  },
  methods: {
    triggerFile () {
      const uploads = document.getElementById('dropDiv')
      const file = document.getElementById('file')
      uploads.addEventListener('click', () => {
        file.click()
      })
    },
    async handleFileUpload (event, i) {
      this.document[i] = event.target.files
      const formData = new FormData()
      const imagefile = document.querySelector('#file')
      console.log(imagefile.files[0])
      const msg = document.getElementById('msg-div')
      const msgDiv = document.getElementById('msg')
      const uploadedFile = document.getElementById('uploadedFile')
      const uploadedSize = document.getElementById('uploadedSize')
      const responceDiv = document.getElementById('responceDiv')
      const size = parseFloat(parseInt(imagefile.files[0].size) / 1024 / 1024).toFixed(2)
      if (size > this.size || imagefile.files[0].type !== this.mimetype) {
        uploadedSize.textContent = size
        msgDiv.style.display = 'block'
        uploadedFile.textContent = imagefile.files[0].name
        msg.style.display = 'none'
        formData.append(this.name, imagefile.files[0], this.loops.id)
        responceDiv.textContent = 'Try to upload large file Or Bad file format'
        responceDiv.style.color = 'red'
      } else {
        uploadedSize.textContent = size
        msgDiv.style.display = 'block'
        uploadedFile.textContent = imagefile.files[0].name
        msg.style.display = 'none'
        formData.append(this.name, imagefile.files[0])
        formData.append('id', this.loops.id)
        if (localStorage.getItem('logged_user')) {
          this.logger = JSON.parse(localStorage.getItem('logged_user'))
        }
        this.header = this.logger.accessToken
        responceDiv.textContent = 'please wait...'
        await axios.post(this.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.header,
          },
        }).then(response => {
          responceDiv.innerHTML = response.data.message
          responceDiv.style.color = '#00b782'
          if (response.data.success) {
            localStorage.setItem('document', response.data.document)
            uploadedSize.textContent = size
            msgDiv.style.display = 'block'
            uploadedFile.textContent = imagefile.files[0].name
            this.$emit('getResponse', 'saved')
            this.$emit('getResponse', 'completed')
          }
        })
      }
    },
  },
}
</script>
<style>
.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}

.dropDiv {
  background-color: rgba(89, 87, 85, 0.06);
  padding-top: 17px;
  position: relative;
  text-align: center;
  height: 100px;
  cursor: pointer;
  border: dashed;
}
</style>
